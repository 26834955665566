<template>
  <div>
    <b-row>
      <b-col sm="7">
        <RWCard>
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M9.879 12.707l-2.122 2.121a1 1 0 101.415 1.415l2.12-2.122.708.707a2 2 0 010 2.829l-2.828 2.828a2 2 0 01-2.829 0l-2.828-2.828a2 2 0 010-2.829L6.343 12a2 2 0 012.829 0l.707.707z" fill="#000" opacity=".3"/><path d="M14.121 11.293l2.122-2.121a1 1 0 00-1.415-1.415l-2.12 2.122L12 9.172a2 2 0 010-2.829l2.828-2.828a2 2 0 012.829 0l2.828 2.828a2 2 0 010 2.829L17.657 12a2 2 0 01-2.829 0l-.707-.707z" fill="#000"/><path d="M4.222 4.222a1 1 0 011.414 0L7.05 5.636A1 1 0 115.636 7.05L4.222 5.636a1 1 0 010-1.414zm5.121-2.707a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zM1.686 9.172a1 1 0 011-1h2a1 1 0 010 2h-2a1 1 0 01-1-1zM19.657 19.657a1 1 0 000-1.414l-1.414-1.415a1 1 0 00-1.415 1.415l1.415 1.414a1 1 0 001.414 0zm2.707-5.121a1 1 0 00-1-1h-2a1 1 0 000 2h2a1 1 0 001-1zm-7.657 7.656a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1z" fill="#000" opacity=".3"/></g></svg>
              Link Referal Utama
            </h3>
          </template>

          <!-- body -->
          <template v-slot:body>
            <p>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <div class="row">
              <div class="col-sm-8">
                <b-form-group>
                  <b-form-input
                    id="input-horizontal"
                    required
                    placeholder="this is placeholder"
                    v-model="form.url"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-4">
                <b-button variant="outline-success" block>EDIT LINK</b-button>
              </div>
            </div>
          </template>
        </RWCard>
      </b-col>
      <b-col>
        <RWCard>
          <!-- title cart -->
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4.05 17.485l-1.45-1.45a1 1 0 010-1.414l3.536-3.535L7.55 12.5a1 1 0 101.414-1.414L7.55 9.672 14.621 2.6a1 1 0 011.415 0l1.45 1.45a2.5 2.5 0 003.464 3.464l1.45 1.45a1 1 0 010 1.414l-7.072 7.07-1.414-1.413A1 1 0 0012.5 17.45l1.414 1.414-3.535 3.535a1 1 0 01-1.415 0l-1.45-1.45a2.5 2.5 0 00-3.464-3.464zm5.622-4.278a1 1 0 000 1.414l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 0z" fill="#000" opacity=".3"/></g></svg>
              Kupon
            </h3>
          </template>

          <!-- body -->
          <template v-slot:body>
            <p>
              Anda juga bisa menggunakan kupon untuk dimasukkan calon pelanggan
              pada saat memesan:
            </p>

            <b-row>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input
                    id="input-horizontal"
                    required
                    placeholder="this is placeholder"
                    v-model="form.kupon"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-button variant="outline-success" block>EDIT </b-button>
              </b-col>
            </b-row>
          </template>
        </RWCard>
      </b-col>
    </b-row>

    <br />

    <div class="row">
      <div class="col-sm-6">
        <RWCard>
          <!-- title cart -->
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4 6h16a1 1 0 011 1v1a1 1 0 01-1 1H4a1 1 0 01-1-1V7a1 1 0 011-1zm1 5h5a1 1 0 011 1v7a1 1 0 01-1 1H5a1 1 0 01-1-1v-7a1 1 0 011-1zm9 0h5a1 1 0 011 1v7a1 1 0 01-1 1h-5a1 1 0 01-1-1v-7a1 1 0 011-1z" fill="#000"/><path d="M14.445 2.168a1 1 0 011.11 1.664L12 6.202l-3.555-2.37a1 1 0 011.11-1.664L12 3.798l2.445-1.63z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
              Promo Khusus (v.1.1)
            </h3>
          </template>

          <!-- body -->
          <template v-slot:body>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <div class="text-right">
              <b-button variant="outline-danger">LIHAT</b-button>
            </div>
          </template>
        </RWCard>
      </div>

      <div class="col-sm-6">
        <RWCard>
          <!-- title cart -->
          <template v-slot:title>
            <h3 class="kt-portlet__head-title">
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M8.4 17H5c-1.657 0-3-1.843-3-3.5S3.343 10 5 10h1V8h15v3.5A7.5 7.5 0 018.4 17zm-1.535-2a7.455 7.455 0 01-.849-3H5c-.552 0-1 .948-1 1.5S4.448 15 5 15h1.865z" fill="#000"/><rect fill="#000" opacity=".3" x="6" y="21" width="15" height="2" rx="1"/><path d="M8.116 0l1.157 1.153a2.195 2.195 0 00.242 3.307l.485.362-.977 1.31-.485-.363A3.829 3.829 0 018.116 0zM13.116 1l1.157 1.153a2.195 2.195 0 00.242 3.307l.485.362-.977 1.31-.485-.363A3.829 3.829 0 0113.116 1zM18.116 0l1.157 1.153a2.195 2.195 0 00.242 3.307l.485.362-.977 1.31-.485-.363A3.829 3.829 0 0118.116 0z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>
              Link Spesifik Produk
            </h3>
          </template>

          <!-- body -->
          <template v-slot:body>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </template>
        </RWCard>
      </div>
    </div>

    <br />
    <RWCard>
      <!-- title cart -->
      <template v-slot:title>
        <h3 class="kt-portlet__head-title">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path opacity=".2" d="M0 0h24v24H0z"/><path d="M4.5 7h5a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3zm9 8h5a1.5 1.5 0 010 3h-5a1.5 1.5 0 010-3z" fill="#000" opacity=".3"/><path d="M17 11a3 3 0 110-6 3 3 0 010 6zM6 19a3 3 0 110-6 3 3 0 010 6z" fill="#000"/></g></svg>
          Banner Link
        </h3>
      </template>

      <!-- body -->
      <template v-slot:body>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
        <p>
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum.
        </p>
        <div class="text-center">
          <b-button v-b-toggle.collapse-2 class="m-1" variant="outline-warning"
            >LIHAT BANNER</b-button
          >
        </div>

        <!-- Element to collapse -->
        <b-collapse id="collapse-2">
          <b-form-group label="Leader Board 728 x 90" description="">
            <b-form-input
              placeholder="Enter your name"
              v-model="form.banner"
            ></b-form-input>
          </b-form-group>
        </b-collapse>
      </template>
    </RWCard>
  </div>
</template>

<script>
import RWCard from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    RWCard
  },
  data() {
    return {
      form: {
        url: "https://afiliasi.rumahweb.com/ref/99999",
        kupon: "KUPON-DISKONFAIZ",
        banner: "https://afiliasi.rumahweb.com/ref/99999"
      },
      viewBanner: false
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Afiliasi", route: "afiliasi/home" },
      { title: "Link Afiliasi" }
    ]);
  }
};
</script>
